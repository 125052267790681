import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "context/AppContext";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button, buttonVariants } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  ChevronDown,
  LogOut,
  Moon,
  Sun,
  Bell,
  X,
  SortAsc,
  SortDesc,
  RefreshCw,
  Wrench,
  Filter,
  Activity,
  FilterX,
  Info,
  RefreshCcw,
} from "lucide-react";
import NotificationDetails from "routes/private/Notifications/NotificationDetails";
import { formatDistanceToNow } from "date-fns";
import { useToast } from "components/ui/use-toast";
import { ScrollArea } from "components/ui/scroll-area";
import { Skeleton } from "components/ui/skeleton";
import { Notification } from "types";
import { cn } from "lib/utils";
import apiClient from "api";

const Header = () => {
  const { toast } = useToast();
  const { state, dispatch } = useAppState();
  const { isDarkMode } = state.settings;
  const { account_token } = state.user;
  const { isAdmin } = state.auth;
  const location = useLocation();

  const [isClearing, setIsClearing] = useState(false);
  const [retrievingToken, setRetrievingToken] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [loadingNotifications, setLoadingNotification] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [filteredNotifications, setFilteredNotifications] = useState<
    Notification[]
  >([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [selectedNotificationType, setSelectedNotificationType] =
    useState<number>(-1);
  const [showNotificationDetails, setShowNotificationDetails] =
    useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null);
  const [notificationFilters, setNotificationFilters] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchNotifications(true);
      fetchNotificationSources();
    }, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!openNotification) return;
    fetchNotifications(true);

    // eslint-disable-next-line
  }, [openNotification]);

  const fetchNotificationSources = async () => {
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_notification_sources"
      );
      const sources = data.message || [];
      setNotificationFilters([
        { id: -1, name: "All" },
        ...sources.map((s: any) => ({
          id: s.notification_source_id,
          name: s.notification_source_description,
        })),
      ]);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (selectedNotificationType === -1) {
      setFilteredNotifications(notifications);
      return;
    }
    setFilteredNotifications(
      notifications.filter(
        (n) => n.notification_type === selectedNotificationType
      )
    );

    // eslint-disable-next-line
  }, [selectedNotificationType, notifications]);

  const fetchNotifications = async (clearFilter: boolean) => {
    if (clearFilter) setSelectedNotificationType(-1);

    setShowNotificationDetails(false);
    setSelectedNotification(null);
    setLoadingNotification(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_notifications");
      setNotifications(data?.message ?? []);
    } catch (error) {
      console.log("Error", error);
      toast({
        description: "Failed to fetch notifications",
        variant: "destructive",
      });
    } finally {
      setLoadingNotification(false);
    }
  };

  const getTempToken = async (appName: "socialguru" | "newsguru") => {
    setRetrievingToken(true);
    try {
      const { data } = await apiClient.get("/get_temp_token", {
        headers: {
          token: account_token,
        },
      });
      const tempToken = data?.message.temporary_token || null;

      setRetrievingToken(false);
      if (tempToken) {
        redirectToNewsGuru(tempToken, appName);
      }
    } catch (error) {
      setRetrievingToken(false);
    }
  };

  // Redirect user to App B
  const redirectToNewsGuru = (
    tempToken: string,
    appName: "socialguru" | "newsguru"
  ) => {
    let url = "";
    if (appName === "newsguru") {
      url = process.env.REACT_APP_NEWSGURU_URL + "?refId=" + tempToken || "";
    }
    if (appName === "socialguru") {
      url = process.env.REACT_APP_SOCIALGURU_URL + "?refId=" + tempToken || "";
    }

    window.open(url, "_blank");
  };

  const toggleDarkMode = () => {
    dispatch({ type: "SET_DARK_MODE", payload: !isDarkMode });
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedNotifications = [...filteredNotifications].sort((a, b) =>
    sortOrder === "asc"
      ? new Date(a.reference_date).getTime() -
        new Date(b.reference_date).getTime()
      : new Date(b.reference_date).getTime() -
        new Date(a.reference_date).getTime()
  );

  const removeNotification = async (id: number) => {
    setIsClearing(true);
    try {
      await apiClient.post(
        "/admin_creative_clear_notification",
        {},
        {
          headers: {
            request: JSON.stringify({
              notification_id: id,
            }),
          },
        }
      );
      const clearFilter = false;
      fetchNotifications(clearFilter);
    } catch (error) {
      toast({
        description: "Failed to clear notifications",
        variant: "destructive",
      });
    } finally {
      setIsClearing(false);
    }
  };

  const getIconByType = (type: number) => {
    switch (type) {
      case 4:
        return <Activity className="text-blue-500 size-5" />;
      case 2:
        return <RefreshCcw className="text-red-500 size-5" />;
      case 1:
        return <Wrench className="text-blue-500 size-5" />;
      default:
        return <Info className="text-blue-500 size-5" />;
    }
  };

  return (
    <nav className="w-full backdrop-blur-sm bg-white/30 dark:bg-black/30 z-10 border-b border-gray-200 dark:border-neutral-800 px-4 py-2.5">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center">
          <span className="flex mr-6">
            <Link to="/">
              <img
                src={`${
                  isDarkMode ? "/logo-main-white.png" : "/logo-main-black.png"
                }`}
                className="h-10"
                alt="CretiveGuru AI"
              />
            </Link>
          </span>
        </div>
        <div className="flex justify-between items-center lg:order-2  space-x-2 sm:space-x-4">
          <div className="sm:inline-block hidden">
            {isAdmin && account_token && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    size="sm"
                    disabled={retrievingToken}
                    variant="outline"
                  >
                    Apps <ChevronDown className="size-4 ml-2" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Switch to</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <div
                      onClick={() => {
                        if (retrievingToken) return;
                        getTempToken("socialguru");
                      }}
                      className={cn(
                        buttonVariants({ variant: "secondary", size: "lg" }),
                        "w-full"
                      )}
                    >
                      Social Guru
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <div
                      onClick={() => {
                        if (retrievingToken) return;
                        getTempToken("newsguru");
                      }}
                      className={cn(
                        buttonVariants({ variant: "secondary", size: "lg" }),
                        "w-full"
                      )}
                    >
                      News Guru
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
          {/* Notification */}

          <Popover open={openNotification} onOpenChange={setOpenNotification}>
            <PopoverTrigger asChild className="relative">
              {location.pathname !== "/notifications" && (
                <Button variant="ghost" size="icon">
                  <Bell className="size-[18px]" strokeWidth={2} />

                  {notifications.length > 0 && (
                    <span className="absolute top-0 right-0 flex items-center justify-center h-4 w-4 bg-red-500 text-white text-[10px] rounded-full">
                      {notifications.length}
                    </span>
                  )}
                </Button>
              )}
            </PopoverTrigger>
            <PopoverContent className="w-[380px] p-4 rounded-lg shadow-lg border border-muted">
              {showNotificationDetails && selectedNotification ? (
                <div className="min-h-[420px]">
                  <NotificationDetails
                    notification={selectedNotification}
                    goBack={() => {
                      setSelectedNotification(null);
                      setShowNotificationDetails(false);
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-lg font-semibold">
                      Notifications ({filteredNotifications.length})
                    </h2>
                    <div className="flex items-center space-x-2">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            size="icon"
                            variant={
                              selectedNotificationType === -1
                                ? "secondary"
                                : "destructive"
                            }
                            aria-label="Filter by type"
                          >
                            {selectedNotificationType === -1 ? (
                              <Filter className="size-4" />
                            ) : (
                              <FilterX className="size-4" />
                            )}
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-36">
                          <DropdownMenuLabel>Source type</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          <DropdownMenuGroup>
                            {notificationFilters.map((item) => (
                              <DropdownMenuCheckboxItem
                                key={item.id}
                                checked={selectedNotificationType === item.id}
                                onCheckedChange={() =>
                                  setSelectedNotificationType(item.id)
                                }
                              >
                                {item.name}
                              </DropdownMenuCheckboxItem>
                            ))}
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      <Button
                        size="icon"
                        variant="secondary"
                        onClick={toggleSortOrder}
                        aria-label="Sort by date"
                      >
                        {sortOrder === "asc" ? (
                          <SortAsc className="size-4" />
                        ) : (
                          <SortDesc className="size-4" />
                        )}
                      </Button>
                      <Button
                        size="icon"
                        variant="secondary"
                        onClick={() => {
                          if (loadingNotifications) return;
                          fetchNotifications(false);
                        }}
                        aria-label="Fetch notifications"
                      >
                        <RefreshCw
                          className={`size-4 ${
                            loadingNotifications ? "animate-spin" : ""
                          }`}
                        />
                      </Button>
                    </div>
                  </div>

                  <ScrollArea className="h-[420px]">
                    <ul className="space-y-4 py-3">
                      {loadingNotifications
                        ? Array.from({ length: 10 }).map((_, index) => (
                            <Skeleton key={index} className="h-14 w-full" />
                          ))
                        : sortedNotifications.map((notification) => (
                            <li
                              onClick={() => {
                                setShowNotificationDetails(true);
                                setSelectedNotification(notification);
                              }}
                              key={notification.notification_id}
                              className={`flex justify-between items-center hover:bg-secondary p-3 rounded-lg border cursor-pointer`}
                            >
                              <div className="flex items-center space-x-3">
                                {getIconByType(notification.notification_type)}
                                <div className="flex-1">
                                  {notification.account_name && (
                                    <p className="text-xs text-muted-foreground font-semibold">
                                      {notification.account_name}
                                    </p>
                                  )}

                                  <p className="text-xs font-medium text-primary">
                                    {notification.notification_text
                                      .trim()
                                      .replace(/-$/, "")}
                                  </p>

                                  <p className="text-xs text-muted-foreground">
                                    {formatDistanceToNow(
                                      new Date(notification.reference_date)
                                    )}{" "}
                                    ago
                                  </p>
                                </div>
                              </div>
                              {notification.can_clear === 1 && (
                                <button
                                  disabled={isClearing}
                                  className="p-2 ml-2 bg-muted hover:bg-muted-foreground hover:text-secondary rounded-md disabled:cursor-not-allowed"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeNotification(
                                      notification.notification_id
                                    );
                                  }}
                                  aria-label="Remove notification"
                                >
                                  <X className="size-4" />
                                </button>
                              )}
                            </li>
                          ))}
                    </ul>

                    {!loadingNotifications &&
                      sortedNotifications.length === 0 && (
                        <p className="text-sm text-gray-500 mt-4 text-center">
                          No notifications
                        </p>
                      )}
                  </ScrollArea>
                  {sortedNotifications.length > 1 && (
                    <Link to="/notifications">
                      <Button
                        className="w-full mt-4"
                        variant={"secondary"}
                        onClick={() => {
                          setOpenNotification(false);
                        }}
                      >
                        View all
                      </Button>
                    </Link>
                  )}
                </div>
              )}
            </PopoverContent>
          </Popover>

          {/* Dark/Light Icon */}

          <Button
            onClick={toggleDarkMode}
            size="icon"
            variant="ghost"
            title="Toggle light/dark mode"
          >
            {isDarkMode ? (
              <Moon className="size-[18px]" strokeWidth={2} />
            ) : (
              <Sun className="size-[18px]" strokeWidth={2} />
            )}
          </Button>

          {/* User profile */}

          <Button
            title="Log out"
            variant="ghost"
            size="icon"
            onClick={() => {
              dispatch({ type: "LOGOUT" });
              window.location.href = "/login";
            }}
          >
            <LogOut className="size-[18px]" strokeWidth={2} />
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
