import { format, parse } from 'date-fns';

export const normalizeTime = (
    times: { id: number; time: string; timezone: string }[]
) => {
    return times
        .map((t) => `${t.time.split(":")[0]}:${t.time.split(":")[1]}`)
        .join(", ");
};

export const getUTCOffset = (date: Date) => {
    const time = new Date(date)
    const utcOffsetMinutes = time.getTimezoneOffset();

    // Convert the UTC offset from minutes to hours and minutes
    const hours = Math.abs(Math.floor(utcOffsetMinutes / 60));
    const minutes = Math.abs(utcOffsetMinutes % 60);
    const utcOffset = `${utcOffsetMinutes < 0 ? "+" : "-"}${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

    return utcOffset;
};

export const truncateTextByLength = (text: string, length: number): string => {
    if (!text || text === "") return ""

    return text.length > length ? `${text.substring(0, length)}...` : text

}

export const getHHMM = (time: string) => {
    const timeStr = time.split(":");
    const lastData = timeStr[2].split(" ");

    return `${timeStr[0]}:${timeStr[1]} ${lastData[1] || ""}`;
};

export const getInitials = (firstName: string, lastName?: string): string => {

    if (firstName.includes("@") && !lastName) {
        return firstName.charAt(0).toUpperCase()
    }

    if (lastName && firstName) {
        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    }

    return ""

}

export const isValidImageUrl = (url: string, callback: (param: boolean) => void) => {
    let img = new Image();

    img.onload = () => {
        callback(true);
    };

    img.onerror = () => {
        callback(false);
    };

    img.src = url;
}

export const formatTime = (time: number, hrs?: boolean, fullTime?: boolean) => {
    if (hrs) {
        const hours = Math.floor(Math.abs(time) / 3600);
        const minutes = Math.floor((Math.abs(time) % 3600) / 60);
        const sign = time < 0 ? "-" : "";

        return `${sign}${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`;
    } else if (fullTime) {
        const hours = Math.floor(Math.abs(time) / 3600);
        const minutes = Math.floor((Math.abs(time) % 3600) / 60);
        const seconds = Math.abs(time) % 60;
        const sign = time < 0 ? "-" : "";

        return `${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;

    } else {

        const minutes = Math.floor((Math.abs(time) % 3600) / 60);
        const seconds = Math.abs(time) % 60;
        const sign = time < 0 ? "-" : "";

        return `${sign}${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    }

};

export const parseDate = (dateString: string) => {
    const formats = ['yyyy/MM/dd', 'dd/MM/yyyy', 'MM/dd/yyyy']; // Possible date formats
    let parsedDate: Date | null = null;

    for (const fmt of formats) {
        try {
            parsedDate = parse(dateString, fmt, new Date());
            if (!isNaN(parsedDate.getTime())) break; // Use getTime() to check if valid Date
        } catch (error) {
            continue; // Continue if parsing fails
        }
    }

    if (parsedDate && !isNaN(parsedDate.getTime())) {
        return format(parsedDate, 'MM/dd/yyyy'); // Convert to MM/DD/YYYY
    } else {
        throw new Error('Invalid date format');
    }
};

export const convertToYYYYMMDD = (dateString: string) => {
    const parsedDate = parseDate(dateString)
    const date = new Date(parsedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function formatDateTime(date: Date, showTime?: boolean, showTZ?: boolean, fullMonth?: boolean): string {
    const monthNames = fullMonth
        ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        : ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();


    if (!showTime) return `${monthNames[monthIndex]}. ${day}, ${year}`

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    if (!showTZ) return `${monthNames[monthIndex]}. ${day}, ${year}, ${hours}:${minutesStr}${ampm}`

    // The timezone is hardcoded as 'PDT'. You might need to adjust this based on your requirements.
    const timezone = getTimezoneName(date).split(", ")[1]

    return `${monthNames[monthIndex]}. ${day}, ${year}, ${hours}:${minutesStr}${ampm} ${timezone}`;
}

export function replaceWithBullet(input: string): string {
    if (!input) return ""
    return input.split('').map(() => '•').join('');
}

export function getTimezoneName(date: Date) {
    return new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date);
}

export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...funcArgs: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    return function executedFunction(...args: Parameters<T>): void {
        const later = () => {
            clearTimeout(timeout as ReturnType<typeof setTimeout>);
            func(...args);
        };

        clearTimeout(timeout as ReturnType<typeof setTimeout>);
        timeout = setTimeout(later, wait);
    };
}



const createImage = (options: any) => {
    options = options || {};
    const img = document.createElement("img");
    if (options.src) {
        img.src = options.src;
    }
    return img;
};

const copyToClipboard = async (pngBlob: any) => {
    try {
        await navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
                [pngBlob.type]: pngBlob
            })
        ]);
        console.log("Image copied");
    } catch (error) {
        console.error(error);
    }
};

const convertToPng = (imgBlob: any) => {
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    const imageEl = createImage({ src: window.URL.createObjectURL(imgBlob) });
    imageEl.onload = (e: any) => {
        canvas.width = e.target.width;
        canvas.height = e.target.height;
        ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
        canvas.toBlob(copyToClipboard, "image/png", 1);
    };
};

export const copyImg = async (src: string) => {
    const img = await fetch(src);
    const imgBlob = await img.blob();
    const extension = src.split(".").pop();
    const supportedToBeConverted = ["jpeg", "jpg", "gif"];
    if (supportedToBeConverted.indexOf(extension!.toLowerCase())) {
        return convertToPng(imgBlob);
    } else if (extension!.toLowerCase() === "png") {
        return copyToClipboard(imgBlob);
    }
    console.error("Format unsupported");
    return;
}

export function deepEqual(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);

        if (
            // eslint-disable-next-line
            areObjects && !deepEqual(val1, val2) ||
            // eslint-disable-next-line
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object: any) {
    return object != null && typeof object === 'object';
}

export function toStartCase(input?: string, firstLetterOnly: boolean = false): string {
    if (!input) return "";

    // Replace underscores, hyphens, and spaces with a space, then split the camel case
    const words = input.replace(/[_-]+/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

    if (firstLetterOnly) {
        // Capitalize only the first letter of the first word
        return words.map((word, index) => {
            return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase();
        }).join(' ');
    } else {
        // Capitalize the first letter of each word
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
}

export function getDateSuffix(day: number): string {
    if (day > 31 || day < 1) {
        throw new Error("Invalid day number");
    }

    const j = day % 10,
        k = day % 100;
    if (j === 1 && k !== 11) {
        return day + "st";
    }
    if (j === 2 && k !== 12) {
        return day + "nd";
    }
    if (j === 3 && k !== 13) {
        return day + "rd";
    }
    return day + "th";
}

export function sanitizeString(str: string): string {
    return str.replace(/[^\x20-\x7E]/g, ''); // Replaces non-ASCII characters
}


export const getDeleteEndpoint = (social: string) => {
    switch (social) {
        case "linkedin":
            return "/delete_linkedin_connection";
        case "facebook":
            return "/delete_facebook_connection";
        case "twitter":
            return "/delete_twitter_connection";
        default:
            return "";
    }
};

export const getCreateEndpoint = (social: string) => {
    switch (social) {
        case "linkedin":
            return "/create_linkedin_connection";
        case "facebook":
            return "/create_facebook_connection";
        case "twitter":
            return "/create_twitter_connection";
        default:
            return "";
    }
};

export const getSuccessEndpoint = (social: string) => {
    switch (social) {
        case "linkedin":
            return "/linkedin_success";
        case "facebook":
            return "/facebook_success";
        case "twitter":
            return "/twitter_success";
        default:
            return "";
    }
};