import { useState } from "react";
import { CheckIcon } from "lucide-react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { cn } from "lib/utils";

type SelectProps = {
  value: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  showInput?: boolean;
  customTrigger?: React.ReactNode;
  onSelect: (val: string) => void;
  data: { value: string; label: string }[];
};

const CustomSelect = ({
  data,
  label,
  value,
  loading,
  disabled,
  showInput,
  customTrigger,
  onSelect,
}: SelectProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {customTrigger ? (
          customTrigger
        ) : (
          <Button
            disabled={Boolean(disabled)}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            {loading ? (
              "Loading..."
            ) : value ? (
              <span className="max-w-[300px] truncate">
                {data.find((d) => d.value === value)?.label}
              </span>
            ) : (
              `Select ${label}...`
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent
        className={`${customTrigger ? "w-[200px]" : "w-[380px]"} p-0`}
      >
        <Command>
          {showInput && (
            <CommandInput placeholder={`Select ${label}...`} className="h-9" />
          )}
          <CommandList>
            <CommandEmpty>No data.</CommandEmpty>
            <CommandGroup>
              {data.map((d) => (
                <CommandItem
                  key={d.value}
                  value={d.value}
                  onSelect={(currentValue) => {
                    onSelect(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  {d.label}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      value === d.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
export default CustomSelect;
