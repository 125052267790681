import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppState } from "context/AppContext";
import apiClient from "api";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import CommonTable from "components/Shared/CommonTable";
import { useToast } from "components/ui/use-toast";

type UserAgent = {
  user_agent: string;
};

const UserAgents = () => {
  const { toast } = useToast();
  const { state } = useAppState();
  const { account } = state.user;
  const { isAdmin } = state.auth;
  const { date } = useParams();

  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [logsData, setLogsData] = useState<UserAgent[]>([]);

  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  useEffect(() => {
    if (!date) return;

    const timeout = setTimeout(getLogsData, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [date, account]);

  const getLogsData = async () => {
    const request = {
      creative_id: account?.account_id,
      date_required: date,
    };
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_user_agents", {
        headers: {
          request: JSON.stringify(request),
        },
      });
      setLogsData(data?.message || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnDef<UserAgent>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="w-20">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "user_agent",
      header: "User agent",
      cell: ({ row }) => (
        <div className="">{row.getValue("user_agent") || "Null"}</div>
      ),
    },
  ];

  const table = useReactTable({
    data: logsData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const handleDelete = async () => {
    const selectedItems = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);

    try {
      await Promise.all(
        selectedItems.map(async (item) => {
          await apiClient.delete("/admin_creative_delete_user_agent", {
            headers: {
              request: JSON.stringify(item),
            },
          });
        })
      );

      // Use browser notifications
      if (Notification.permission === "granted") {
        new Notification("Items deleted successfully");
      } else {
        toast({ description: "Items deleted successfully" });
      }
    } catch (error) {
      if (Notification.permission === "granted") {
        new Notification("Error deleting items");
        toast({ description: "Error deleting items", variant: "destructive" });
      } else {
        toast({ description: "Error deleting items", variant: "destructive" });
      }
    } finally {
      getLogsData();
      table.resetRowSelection(true);
    }
  };

  // Request browser notification permission on page load
  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  return (
    <>
      <Breadcrumbs
        home={{ title: "Overview", path: "/" }}
        routes={[{ title: "User agents", path: "" }]}
      />
      <CommonTable
        table={table}
        data={logsData}
        columns={columns}
        loading={loading}
        name="User agents"
        showSelectedCount={true}
        searchComponent={
          <div className="flex justify-between items-center py-4">
            <Input
              placeholder="Search..."
              value={
                (table.getColumn("user_agent")?.getFilterValue() as string) ??
                ""
              }
              onChange={(event) =>
                table
                  .getColumn("user_agent")
                  ?.setFilterValue(event.target.value)
              }
              className="max-w-sm h-8"
            />

            {isAdmin && Object.keys(rowSelection).length > 0 && (
              <Button variant="destructive" onClick={() => setOpenAlert(true)}>
                Delete ({Object.keys(rowSelection).length})
              </Button>
            )}
          </div>
        }
      />

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete all the
              selected items.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UserAgents;
