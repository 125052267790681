import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { Input } from "components/ui/input";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import AddSitemapTypeDialog from "./AddProfileDialog";
import { Profile } from "types";
import apiClient from "api";

type Props = {
  data: Profile[];
  loading: boolean;
  fetchData: () => void;
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DataTableProfiles({
  data,
  loading,
  fetchData,
  setOpenAdd,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<Profile>[] = [
    {
      accessorKey: "profile_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div className="">{row.original.profile_id}</div>,
    },
    {
      accessorKey: "profile_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Profile name" />
      ),
      cell: ({ row }) => <div className="">{row.original.profile_name}</div>,
    },
    {
      accessorKey: "creative_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Creative ID" />
      ),
      cell: ({ row }) => <div className="">{row.original.creative_id}</div>,
    },
    {
      accessorKey: "account_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Account name" />
      ),
      cell: ({ row }) => <div className="">{row.original.account_name}</div>,
    },
    {
      id: "actions",
      header: "Actions",
      enableHiding: false,
      cell: ({ row }) => <RowAction row={row} fetchData={fetchData} />,
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <CommonTable
      searchComponent={
        <div className="flex items-center py-4">
          <Input
            placeholder="Filter profiles..."
            value={
              (table.getColumn("profile_name")?.getFilterValue() as string) ??
              ""
            }
            onChange={(event) =>
              table
                .getColumn("profile_name")
                ?.setFilterValue(event.target.value)
            }
            className="max-w-sm h-8"
          />

          <Button
            className="ml-auto"
            size="sm"
            onClick={() => setOpenAdd(true)}
          >
            Add profile
          </Button>
        </div>
      }
      table={table}
      name="Profiles"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
}

const RowAction = ({ row, fetchData }: DataTableRowActionsProps<Profile>) => {
  const { toast } = useToast();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const data = row.original;

  const deleteSitemapType = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting profile...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_profile", {
        headers: {
          request: JSON.stringify({
            profile_id: data.profile_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Profile deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Profile deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              profile.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteSitemapType}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddSitemapTypeDialog
        fetchData={fetchData}
        open={openEdit}
        onOpenChange={setOpenEdit}
        editData={data}
      />
    </>
  );
};
