import * as React from "react";
import {
  Row,
  ColumnDef,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import AddQuestionSubjectDialog from "./AddQuestionSubjectDialog";
import { QuestionSubject } from "types";
import apiClient from "api";

type Props = {
  loading: boolean;
  data: QuestionSubject[];
  fetchData: () => void;
  setSelectedSubject: React.Dispatch<
    React.SetStateAction<QuestionSubject | null>
  >;
};

export function DataTableQuestionSubjects({
  data,
  loading,
  fetchData,
  setSelectedSubject,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);

  const selectionToggle = (row: Row<QuestionSubject>) => {
    if (selectedRowId === row.original.question_subject_id) {
      setSelectedRowId(null);
    } else {
      setSelectedRowId(row.original.question_subject_id);
    }
  };

  const columns: ColumnDef<QuestionSubject>[] = [
    {
      id: "select",
      header: () => null,
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRowId === row.original.question_subject_id}
          onCheckedChange={() => {
            selectionToggle(row);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "question_subject_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => (
        <div className="">{row.original.question_subject_id}</div>
      ),
    },
    {
      accessorKey: "question_subject_prompt",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="Question subject prompt"
        />
      ),
      cell: ({ row }) => (
        <div
          title={row.original.question_subject_prompt}
          className="w-60 hover:underline cursor-pointer truncate"
          onClick={() => {
            selectionToggle(row);
          }}
        >
          {row.original.question_subject_prompt}
        </div>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      enableHiding: false,
      cell: ({ row }) => <RowAction row={row} fetchData={fetchData} />,
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  React.useEffect(() => {
    if (selectedRowId) {
      const selectedRowData =
        data.find((row) => row.question_subject_id === selectedRowId) || null;
      setSelectedSubject(selectedRowData);
    } else {
      setSelectedSubject(null);
    }
    // eslint-disable-next-line
  }, [selectedRowId, data]);

  return (
    <CommonTable
      table={table}
      name="Question subjects"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
}

const RowAction = ({
  row,
  fetchData,
}: DataTableRowActionsProps<QuestionSubject>) => {
  const { toast } = useToast();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const data = row.original;

  const deleteQuestionSubject = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting question subject...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_question_subject", {
        headers: {
          request: JSON.stringify({
            question_subject_id: data.question_subject_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Question subject deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Question subject deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              question subject.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteQuestionSubject}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddQuestionSubjectDialog
        fetchData={fetchData}
        open={openEdit}
        onOpenChange={setOpenEdit}
        editData={data}
      />
    </>
  );
};
