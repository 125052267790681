import ImageModalViewer from "components/Dialogs/ImageDialog";
import { ArrowLeft, Copy } from "lucide-react";
import { Button } from "components/ui/button";
import { formatDistanceToNow } from "date-fns";
import { useToast } from "components/ui/use-toast";
import { Notification } from "types";

type ParsedNotificationData = {
  group: string;
  group_description: string | null;
  login_time: number;
  page: string;
  post_completed: number;
  replies: number;
  replies_completed: number;
  task_id: number;
  task_type: number;
  tasks_completed: number;
  tasks_remaining: number;
};

type Props = {
  notification: Notification;
  goBack: () => void;
};

const NotificationDetails = ({ notification, goBack }: Props) => {
  const { toast } = useToast();

  const parseJsonForUI = (jsonString: string) => {
    try {
      const parsedObject = JSON.parse(jsonString);
      return parsedObject;
    } catch (error) {
      console.error("Invalid JSON:", error);
      return {};
    }
  };

  const formatJsonForUI = (obj: ParsedNotificationData) => {
    return JSON.stringify(obj, null, 2);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center w-full">
          <Button onClick={goBack} variant="ghost" size="icon">
            <ArrowLeft className="size-5" />
          </Button>
          <h2 className="text-lg font-semibold ml-2">
            {notification.notification_name} ({notification.notification_id})
          </h2>
        </div>
        <div className="flex items-center space-x-2"></div>
      </div>

      <div className="font-medium">
        <span className="text-muted-foreground text-sm">
          {formatDistanceToNow(new Date(notification.reference_date))} ago
        </span>
        {notification.notification_name && (
          <span className="text-primary text-sm">
            {" - "}
            {notification.notification_name}
          </span>
        )}

        <p className="text-xs mt-1">{notification.notification_text}</p>
      </div>

      {notification.creative_account && (
        <p className="text-xs text-muted-foreground">
          {notification.account_name}
        </p>
      )}

      {/* Screenshot Image */}
      {notification.screenshot_url && (
        <>
          <div className="font-semibold text-sm">Screenshot</div>
          <ImageModalViewer
            imageUrl={notification.screenshot_url}
            altText="Notification Screenshot"
            title="Screenshot preview"
            description={notification.notification_text}
            className="w-52 h-auto mb-4 rounded"
          />
        </>
      )}
      {/* Parsed JSON Data */}
      {notification?.html_response && (
        <>
          <div className="font-semibold text-sm">Task details</div>
          <div className="relative">
            <pre className="bg-secondary p-4 rounded-lg text-xs overflow-auto">
              {formatJsonForUI(
                parseJsonForUI(
                  notification.html_response
                ) as ParsedNotificationData
              )}
            </pre>
            <Button
              onClick={() => {
                const parsedData = parseJsonForUI(notification.html_response);
                if (parsedData) {
                  navigator.clipboard.writeText(
                    JSON.stringify(parsedData, null, 2)
                  );
                }
                toast({
                  description: "Task content copied",
                });
              }}
              size="icon"
              className="absolute top-2 right-2 text-xs px-2 py-1"
            >
              <Copy className="size-4" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationDetails;
