import { useState } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { DataTableViewOptions } from "./data-table-view-options";

import { channel, frequency } from "./data";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  setGlobalFilter: (value: string) => void;
}

export function DataTableToolbar<TData>({
  table,
  setGlobalFilter,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const [searchTerm, setSearchTerm] = useState<string>("");

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col w-full sm:flex-row sm:flex-1 sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Input
          placeholder="Filter schedules..."
          value={searchTerm}
          onChange={(event) => {
            const value = event.target.value;
            setSearchTerm(value);
            setGlobalFilter(value);
          }}
          className="h-8 w-full sm:w-[250px]"
        />
        <div className="flex space-x-2">
          <DataTableFacetedFilter
            column={table.getColumn("frequency")}
            title="Frequency"
            options={frequency}
          />

          <DataTableFacetedFilter
            column={table.getColumn("channel")}
            title="Channel"
            options={channel}
          />

          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => {
                table.resetColumnFilters();
                setSearchTerm("");
                setGlobalFilter(""); // Reset the global filter
              }}
              className="h-8 sm:px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
