import React, { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";

import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useToast } from "components/ui/use-toast";
import { Input } from "components/ui/input";
import { Sitemap, SitemapType } from "types";
import { useAppState } from "context/AppContext";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: Sitemap | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddSitemapDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [sitemapTypes, setSitemapTypes] = useState<SitemapType[]>([]);
  const [sitemapType, setSitemapType] = useState<SitemapType | null>(null);
  const [sitemapUrl, setSitemapUrl] = useState("");

  useEffect(() => {
    if (!editData) return;
    setSitemapUrl(editData.sitemap_url);
    setSitemapType(
      sitemapTypes.find((st) => st.content_type === editData.sitemap_type) ||
        null
    );
  }, [editData, sitemapTypes]);

  useEffect(() => {
    if (open) {
      getContent();
    } else {
      resetForm();
    }

    // eslint-disable-next-line
  }, [open]);

  const resetForm = () => {
    setSitemapUrl("");
    setSitemapType(null);
  };

  const getContent = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_sitemap_types");

      const list: SitemapType[] = data?.message || [];
      setSitemapTypes(list);
    } catch (error) {
      console.log("error:", error);
    } finally {
    }
  };

  const addSitemap = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_sitemap",
          {},
          {
            headers: {
              request: JSON.stringify({
                sitemap_id: editData?.sitemap_id,
                creative_id: account?.account_id,
                sitemap_type: sitemapType?.content_type,
                sitemap_url: sitemapUrl,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_add_sitemap",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                sitemap_type: sitemapType?.content_type,
                sitemap_url: sitemapUrl,
              }),
            },
          }
        );

        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: "Sitemap already exists.",
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} sitemap</DialogTitle>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addSitemap}>
            <div className="grid gap-2">
              <Label htmlFor="sitemap-url">Sitemap URL</Label>
              <div className="relative">
                <Input
                  id="sitemap-url"
                  required
                  value={sitemapUrl}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setSitemapUrl(val);
                  }}
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="sitemap-type">Sitemap type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(sitemapType?.content_type) || ""}
                  onValueChange={(value) => {
                    if (value === "no data") {
                      if (sitemapType) setSitemapType(null);
                      return;
                    }
                    const st = sitemapTypes.filter(
                      (st) => st.content_type === parseInt(value)
                    );
                    setSitemapType(st[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select sitemap type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {sitemapTypes.length > 0 ? (
                      <>
                        {sitemapType && (
                          <SelectItem value={"no data"}>Unselect</SelectItem>
                        )}
                        {sitemapTypes.map((st) => (
                          <SelectItem
                            key={st.content_type}
                            value={String(st.content_type)}
                          >
                            {st.description}
                          </SelectItem>
                        ))}
                      </>
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddSitemapDialog;
