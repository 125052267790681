import * as React from "react";
import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { v4 as uuidv4 } from "uuid";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { ImageUp, MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";

import ImageCropDialog from "./ImageCrop/ImageCropDialog";
import AddPodcastDialog from "./AddPodcastDialog";
import { Podcast } from "types";
import apiClient from "api";

type Props = {
  data: Podcast[];
  loading: boolean;
  selectedPodcast: Podcast | null;
  fetchData: () => void;
  setSelectedPodcast: React.Dispatch<React.SetStateAction<Podcast | null>>;
};

export const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "https://wip.creativeguru.ai"
    : "https://" + window.location.host;

export function DataTablePodcasts({
  data,
  loading,
  selectedPodcast,
  fetchData,
  setSelectedPodcast,
}: Props) {
  const { toast } = useToast();

  const currentIdToUpload = React.useRef<number | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);

  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, id: number) => {
    e.preventDefault();
    currentIdToUpload.current = id;
    const file = e.dataTransfer.files[0];
    if (file && file.size > 1 * 1000 * 1024) {
      toast({
        title: "File size too large",
        description: "Maximum image size allowed is 1MB",
        variant: "destructive",
      });
      return false;
    }
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    currentIdToUpload.current = id;
    const file = e.target.files?.[0];
    if (file && file.size > 1 * 1000 * 1024) {
      toast({
        title: "File size too large",
        description: "Maximum image size allowed is 1MB",
        variant: "destructive",
      });
      return false;
    }
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file: Blob) => {
    if (!file) {
      console.error("No file to upload");
      return;
    }
    const mimeType = file.type;
    const extension = mimeType.split("/")[1];
    const fileName = `podcast-image-${uuidv4()}.${extension}`;
    const formData = new FormData();
    const renamedFile = new File([file], fileName);
    formData.append("file", renamedFile);
    formData.append("folder", "podcast_images/");

    const fileUrl = `${API_BASE_URL}/images/podcast_images/${fileName}`;

    setIsUploading(true);

    try {
      await axios.post(API_BASE_URL + "/images/podcast_images.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await saveImageUrlToDB(fileUrl);
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const saveImageUrlToDB = async (url: string) => {
    setIsUploading(true);
    try {
      await apiClient.post(
        "/admin_creative_add_podcast_image",
        {},
        {
          headers: {
            request: JSON.stringify({
              podcast_id: currentIdToUpload.current,
              image_url: url,
            }),
          },
        }
      );
      setIsUploading(true);
      currentIdToUpload.current = null;
      fetchData();
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const columns: ColumnDef<Podcast>[] = [
    {
      id: "select",
      header: () => null,
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRowId === row.original.podcast_id}
          onCheckedChange={() => {
            if (selectedRowId === row.original.podcast_id) {
              setSelectedRowId(null);
            } else {
              setSelectedRowId(row.original.podcast_id);
            }
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "podcast_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div className="">{row.original.podcast_id}</div>,
    },
    {
      accessorKey: "image_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Image" />
      ),
      cell: ({ row }) =>
        row.original.image_url ? (
          <div className="bg-muted size-10 rounded">
            <img
              src={row.original.image_url}
              className="object-cover w-full h-full rounded"
              alt={row.original.podcast_title}
            />
          </div>
        ) : (
          <div aria-disabled={isUploading}>
            <div
              className="w-10 h-10 border-dashed border border-gray-400 flex items-center justify-center rounded cursor-pointer"
              onDrop={(e) => handleDrop(e, row.original.podcast_id)}
              onDragOver={handleDragOver}
              onClick={handleClick}
            >
              {isUploading || loading ? (
                <div className="size-4 animate-spin border-2 border-t-muted-foreground rounded-full"></div>
              ) : (
                <ImageUp className="size-4 text-muted-foreground" />
              )}
            </div>
            <input
              disabled={isUploading}
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleFileChange(e, row.original.podcast_id)}
            />
          </div>
        ),
    },
    {
      accessorKey: "podcast_title",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Podcast title" />
      ),
      cell: ({ row }) => <div className="">{row.original.podcast_title}</div>,
    },
    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Start date" />
      ),
      cell: ({ row }) => (
        <div className="w-40">
          {new Date(row.original.start_date).toLocaleString()}
        </div>
      ),
    },
    {
      accessorKey: "start_timestamp",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="Podcast start timestamp"
        />
      ),
      cell: ({ row }) => (
        <div className="">
          {row.original.start_timestamp
            ? new Date(row.original.start_timestamp * 1000).toLocaleString()
            : "-"}
        </div>
      ),
    },
    {
      accessorKey: "description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Description" />
      ),
      cell: ({ row }) => (
        <div className="w-60 truncate">{row.original.description}</div>
      ),
    },
    {
      accessorKey: "podcast_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Podcast URL" />
      ),
      cell: ({ row }) => <div className="">{row.original.podcast_url}</div>,
    },
    {
      accessorKey: "rss_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="RSS URL" />
      ),
      cell: ({ row }) => <div className="">{row.original.rss_url}</div>,
    },
    {
      id: "actions",
      header: "Actions",
      enableHiding: false,
      cell: ({ row }) => (
        <RowAction
          selectedPodcast={selectedPodcast}
          row={row}
          fetchData={fetchData}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  React.useEffect(() => {
    if (selectedRowId) {
      const selectedRowData =
        data.find((row) => row.podcast_id === selectedRowId) || null;
      setSelectedPodcast(selectedRowData);
    } else {
      setSelectedPodcast(null);
    }

    // eslint-disable-next-line
  }, [selectedRowId, data]);

  return (
    <>
      <CommonTable
        table={table}
        name="Podcasts"
        columns={columns}
        data={data}
        loading={loading}
      />
      <ImageCropDialog
        imgSrc={imageSrc}
        onClose={() => setImageSrc(null)}
        onSave={(blobFile) => {
          uploadImage(blobFile);
          setImageSrc(null);
        }}
      />
    </>
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
  selectedPodcast: Podcast | null;
}

const RowAction = ({
  row,
  fetchData,
  selectedPodcast,
}: DataTableRowActionsProps<Podcast>) => {
  const { toast } = useToast();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const data = row.original;

  const deletePodcast = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting podcast...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_podcast", {
        headers: {
          request: JSON.stringify({
            podcast_id: data.podcast_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Podcast deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Podcast deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              podcast.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deletePodcast}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddPodcastDialog
        open={openEdit}
        editData={data}
        selectedPodcast={selectedPodcast}
        fetchData={fetchData}
        onOpenChange={setOpenEdit}
      />
    </>
  );
};
