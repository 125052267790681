import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  ExclamationTriangleIcon,
  EyeClosedIcon,
  EyeOpenIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import axios from "axios";

import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";

import { TOKEN_KEY } from "constants/constants";
import { useAppState } from "context/AppContext";
import { set } from "utils/storage";

const Login = () => {
  const { state, dispatch } = useAppState();
  const { isAuthenticated } = state.auth;
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_API_KEY + "/creative_user_login",
        {},
        {
          headers: {
            email,
            password,
          },
        }
      );

      const user = data?.message;
      setIsSubmitting(false);
      dispatch({
        type: "SET_ADMIN",
        payload: user.admin === 1 ? true : false,
      });
      dispatch({
        type: "LOGIN",
        payload: { user, token: user.token },
      });
      set(TOKEN_KEY, user.token);
      const from = location.pathname || "/";
      navigate(from, { replace: true });
      set("ADMIN_STATE", null);
      set("USER_STATE", null);
    } catch (error) {
      setHasError(true);
      setIsSubmitting(false);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div className="w-full lg:grid min-h-screen lg:grid-cols-2 bg-[url('https://wip.creativeguru.ai/content/img/company/creativeguru/creativeguru-robot-hand.png')] bg-no-repeat bg-center bg-cover">
        <div className="hidden relative lg:block">
          <div className="absolute inset-0 after:content-[''] after:absolute after:inset-0 after:bg-gradient-to-t from-background to-transparent after:bottom-0 after:h-full flex flex-col justify-between items-center py-16">
            <div></div>
            <div className="z-10 text-center cursor-default">
              <h2 className="text-secondary-foreground font-bold text-5xl">
                Creativeguru.ai
              </h2>
              <div className="mt-10 bg-gradient-to-br from-green-400 to-blue-500 text-white py-3 px-12 rounded-full shadow-md font-medium text-lg">
                THE FUTURE OF LEAD GENERATION
              </div>
            </div>
            <div className="z-10 cursor-default"></div>
          </div>
        </div>
        <div className="flex items-center justify-center py-12 inset-0 bg-gradient-to-b lg:bg-gradient-to-t from-background to-transparent h-screen lg:h-full">
          <form
            onSubmit={handleLogin}
            className="mx-auto w-full grid sm:max-w-md space-y-6 p-8 rounded-lg"
          >
            <div className="space-y-2 text-center">
              <h1 className="text-3xl sm:text-4xl font-semibold text-secondary-foreground">
                Login
              </h1>
              {/* <p className="text-base text-secondary-foreground tracking-tight">
                Enter your email below to login to your account.
              </p> */}
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email" className="text-base">
                  Email
                </Label>
                <Input
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  id="email"
                  type="email"
                  placeholder="john@company.com"
                  required
                  className="py-2.5"
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password" className="text-base">
                    Password
                  </Label>
                  {/* <Link
                    to="/forgot-password"
                    className="ml-auto inline-block text-sm underline"
                  >
                    Forgot your password?
                  </Link> */}
                </div>
                <div className="relative">
                  <Input
                    value={password}
                    onChange={(evt) => setPassword(evt.target.value)}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    required
                    className="py-2.5"
                  />
                  <Button
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute top-0 right-0 h-full"
                  >
                    {showPassword ? (
                      <EyeOpenIcon className="size-4" />
                    ) : (
                      <EyeClosedIcon className="size-4" />
                    )}
                  </Button>
                </div>
              </div>
              <Button
                disabled={isSubmitting}
                type="submit"
                size="lg"
                className="w-full"
              >
                {isSubmitting && (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                )}
                Login
              </Button>
            </div>
            {hasError && (
              <Alert variant="destructive">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  Incorrect credentials. Please try again.
                </AlertDescription>
              </Alert>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
