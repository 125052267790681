import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { useAppState } from "context/AppContext";
import { ClientSocial, Connection, SocialChannelType } from "types";
import CustomSelect from "components/Shared/CustomSelect";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: ClientSocial | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddClientSocialDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const { toast } = useToast();

  const [submitting, setSubmitting] = useState(false);
  const [channels, setChannels] = useState<SocialChannelType[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [channel, setChannel] = useState<SocialChannelType | null>(null);
  const [connection, setConnection] = useState<Connection | null>(null);
  const [loadingConnections, setLoadingConnections] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);

  useEffect(() => {
    if (!editData) return;

    const _conn = connections.find((_d) => _d.id === editData.connection_id);
    setConnection(_conn || null);
    const _channel = channels.find(
      (_d) => _d.channel_id === editData.social_media_channel
    );
    setChannel(_channel || null);
    setHoursBetween(editData.hours_between);

    // eslint-disable-next-line
  }, [editData, channels, connections]);

  useEffect(() => {
    if (!open) return;

    const getChannels = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_social_channels",
          {
            headers: {
              request: JSON.stringify({ creative_id: account?.account_id }),
            },
          }
        );

        const _channels: SocialChannelType[] = data?.message || [];
        setChannels(_channels);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    const getConnections = async () => {
      setLoadingConnections(true);
      try {
        const { data } = await apiClient.get("/creative_get_social_dashboard", {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        });

        const connections: Connection[] = data?.message || [];
        setConnections(connections);
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoadingConnections(false);
      }
    };
    getChannels();
    getConnections();
    // eslint-disable-next-line
  }, [open]);

  const addClientSocial = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_update_client_social",
          {},
          {
            headers: {
              request: JSON.stringify({
                client_social_id: editData?.client_social_id,
                creative_id: account?.account_id,
                social_media_type: channel?.channel_id,
                social_connection_id: connection?.id,
                hours_between: hoursBetween,
              }),
            },
          }
        );
        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: data?.message[0].message,
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_add_client_social",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                social_media_type: channel?.channel_id,
                social_connection_id: connection?.id,
                hours_between: hoursBetween,
              }),
            },
          }
        );
        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: data?.message[0].message,
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} client social
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addClientSocial} className="grid gap-4">
            <div className="grid gap-2 w-full">
              <Label htmlFor="channel">Channel</Label>
              <CustomSelect
                value={channel ? String(channel.channel_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setChannel(null);
                    return;
                  }
                  const _channel = channels.find(
                    (_d) => _d.channel_id === parseInt(value)
                  );
                  setChannel(_channel || null);
                }}
                data={channels.map((c) => ({
                  value: String(c.channel_id),
                  label: c.description,
                }))}
                label="channel"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="connection">Connection</Label>
              <CustomSelect
                disabled={loadingConnections || !channel}
                value={connection ? String(connection.id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setConnection(null);
                    return;
                  }
                  const _connection = connections.find(
                    (_d) => _d.id === parseInt(value)
                  );
                  setConnection(_connection || null);
                }}
                data={connections
                  .filter((c) => {
                    if (
                      channel?.description.toLowerCase().includes("x") ||
                      channel?.description.toLowerCase().includes("twitter")
                    ) {
                      return (
                        c.channel.toLowerCase().includes("x") ||
                        c.channel.toLowerCase().includes("twitter")
                      );
                    } else {
                      return channel?.description
                        .toLowerCase()
                        .includes(c.channel.toLowerCase());
                    }
                  })
                  .map((c) => ({
                    value: String(c.id),
                    label: c.connection_name,
                  }))}
                label="connection"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddClientSocialDialog;
