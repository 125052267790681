import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import CustomSelect from "components/Shared/CustomSelect";
import { Actor, Group, GroupAnswerer, Poster } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: GroupAnswerer | null;
  selectedGroup: Group | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddGroupAnswererDialog = ({
  open,
  editData,
  selectedGroup,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [actors, setActors] = useState<Actor[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loadingActors, setLoadingActors] = useState(false);
  const [loadingPosters, setLoadingPosters] = useState(false);
  const [answererList, setAnswererList] = useState<Actor[]>([]);
  const [answerer, setAnswerer] = useState<Actor | null>(null);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterType, setPosterType] = useState<Poster | null>(null);

  useEffect(() => {
    if (!editData) return;
    setPosterType(
      posterTypes.find((p) => p.content_type === editData.answerer_type) || null
    );
    // eslint-disable-next-line
  }, [editData, posterTypes]);

  useEffect(() => {
    if (!editData) return;

    const answerers = actors.filter(
      (item) =>
        item.actor_type === posterType?.content_type &&
        item.creative_id === account?.account_id
    );
    setAnswererList(answerers);
    // eslint-disable-next-line
  }, [editData, posterType]);

  useEffect(() => {
    if (!editData) return;
    setAnswerer(
      answererList.find(
        (a) =>
          a.actor_type === editData.answerer_type &&
          a.creative_id === account?.account_id
      ) || null
    );

    // eslint-disable-next-line
  }, [editData, answererList]);

  useEffect(() => {
    if (!open) return;
    fetchPosterTypes();
    getActorsList();

    // eslint-disable-next-line
  }, [open]);

  const getActorsList = async () => {
    setLoadingActors(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_actors");
      const list: Actor[] = data?.message || [];

      setActors(list ?? []);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoadingActors(false);
    }
  };

  const fetchPosterTypes = async () => {
    setLoadingPosters(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_poster_types");
      const content: Poster[] = data?.message || [];
      setPosterTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingPosters(false);
    }
  };

  const addGroupAnswerer = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_group_answerer",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_answerer_id: editData?.group_answerer_id,
                group_id: selectedGroup?.group_id,
                answerer_type: posterType?.content_type,
                answerer_id: answerer?.actor_id,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_group_answerer",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_id: selectedGroup?.group_id,
                answerer_type: posterType?.content_type,
                answerer_id: answerer?.actor_id,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} group answerer
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addGroupAnswerer} className="grid gap-4">
            <div className="grid gap-2 w-full">
              <Label htmlFor="poster-type">Poster type</Label>
              <CustomSelect
                disabled={loadingPosters}
                value={posterType ? String(posterType?.content_type) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPosterType(null);
                    setAnswerer(null);
                    return;
                  }
                  const type = posterTypes.filter(
                    (p) => p.content_type === parseInt(value)
                  );
                  setPosterType(type[0] || null);
                  setAnswerer(null);
                  const answerers = actors.filter(
                    (item) =>
                      item.actor_type === type[0]?.content_type &&
                      item.creative_id === account?.account_id
                  );
                  setAnswererList(answerers);
                }}
                data={posterTypes.map((t) => ({
                  value: String(t.content_type),
                  label: t.description,
                }))}
                label="poster type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="answerer">Answerer</Label>
              <CustomSelect
                disabled={!Boolean(posterType) || loadingActors}
                value={answerer ? String(answerer?.actor_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setAnswerer(null);
                    return;
                  }
                  const _answerer = answererList.filter(
                    (d) => d.actor_id === parseInt(value)
                  );
                  setAnswerer(_answerer[0] || null);
                }}
                data={answererList.map((t) => ({
                  value: String(t.actor_id),
                  label: t.actor_name,
                }))}
                label="answerer"
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddGroupAnswererDialog;
