import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button, buttonVariants } from "components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import CustomSelect from "components/Shared/CustomSelect";

import { useConnection } from "context/ConnectionContext";
import { Account, Agency, Client } from "types";
import { useAppState } from "context/AppContext";
import { cn } from "lib/utils";
import apiClient from "api";

const Navigation = () => {
  const { state, dispatch } = useAppState();
  const { setIsOpen } = useConnection();
  // const { pathname } = useLocation();
  const { isAdmin } = state.auth;
  const { client, agency, account } = state.user;

  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  // const [loadingCampaigns, setLoadingCampaigns] = useState(false);

  const [clients, setClients] = useState<Client[]>([]);
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  // const [campaigns, setCampaigns] = useState<CampaignList[]>([]);

  useEffect(() => {
    setLoadingAgencies(true);
    const getAgencies = async () => {
      try {
        const { data } = await apiClient.get("/creative_get_agencies");
        setAgencies(data?.message || []);
        setLoadingAgencies(false);
      } catch (error) {
        setLoadingAgencies(false);
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      getAgencies();
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!agency) return;
    setLoadingClients(true);
    const getClients = async () => {
      try {
        const { data } = await apiClient.get("/creative_get_companies", {
          headers: {
            request: JSON.stringify({ agency_id: agency.agency_id }),
          },
        });
        setClients(data?.message || []);
        setLoadingClients(false);
      } catch (error) {
        setLoadingClients(false);
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      getClients();
    }, 200);

    return () => clearTimeout(timeout);
  }, [agency]);

  useEffect(() => {
    if (!client) return;
    setLoadingAccounts(true);
    const getAccounts = async () => {
      try {
        const { data } = await apiClient.get("/creative_get_accounts", {
          headers: {
            request: JSON.stringify({ company_id: client.customer_id }),
          },
        });
        setAccounts(data?.message || []);
        setLoadingAccounts(false);
      } catch (error) {
        setLoadingAccounts(false);
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      getAccounts();
    }, 200);

    return () => clearTimeout(timeout);
  }, [client]);

  useEffect(() => {
    if (!account) return;

    const connectAccount = async () => {
      try {
        const { data } = await apiClient.post(
          "/creative_connect_account",
          {},
          {
            headers: {
              request: JSON.stringify({
                target_account_id: account.account_id,
              }),
            },
          }
        );
        dispatch({
          type: "SET_ACCOUNT_TOKEN",
          payload: data?.message.account_token,
        });
        // getCampaigns();
      } catch (error) {
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      connectAccount();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  // const getCampaigns = async () => {
  //   setLoadingCampaigns(true);
  //   try {
  //     const { data } = await apiClient.get("/creative_get_campaigns", {
  //       headers: {
  //         request: JSON.stringify({ account_id: account?.account_id }),
  //       },
  //     });

  //     setLoadingCampaigns(false);
  //   } catch (error) {
  //     setLoadingCampaigns(false);
  //     console.log("Error", error);
  //   }
  // };

  // Set data
  const setAgency = (a: Agency | undefined) => {
    setClients([]);
    setAccounts([]);

    dispatch({ type: "SET_AGENCY", payload: a });
  };
  const setClinet = (c: Client | undefined) => {
    setAccounts([]);

    dispatch({ type: "SET_CLIENT", payload: c });
  };
  const setAccount = (a: Account | undefined) => {
    dispatch({ type: "SET_ACCOUNT", payload: a });
  };
  // const setCampaign = (c: CampaignList | undefined) => {
  //   dispatch({ type: "SET_CAMPAIGN", payload: c });
  // };

  return (
    <nav className="bg-background sticky top-0">
      <div className="flex flex-col lg:flex-row gap-2 lg:gap-0 justify-between py-3 px-4 border-b border-gray-200 dark:border-neutral-800">
        <div className="flex justify-between w-full">
          <div className="md:hidden block">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="secondary" size="sm">
                  <span className="truncate max-w-[150px]">
                    {account
                      ? account.account_name
                      : client
                      ? client.customer_name
                      : agency
                      ? agency.agency_name
                      : "Agency"}
                  </span>
                  <CaretSortIcon className="size-4 ml-2" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="grid gap-4">
                  <div className="grid gap-2">
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="agency">Agency</Label>
                      <CustomSelect
                        loading={loadingAgencies}
                        customTrigger={
                          <Button
                            variant="secondary"
                            size="sm"
                            className="col-span-2"
                          >
                            <span className="truncate max-w-[150px]">
                              {agency ? agency.agency_name : "Select Agency"}
                            </span>
                            <CaretSortIcon className="size-4 ml-2" />
                          </Button>
                        }
                        value={agency ? String(agency?.agency_id) : ""}
                        onSelect={(value) => {
                          if (value) {
                            const selectedAgency = agencies.find(
                              (ag) => ag.agency_id === parseInt(value)
                            );
                            setAgency(selectedAgency);
                            if (
                              agency &&
                              agency.agency_id !== parseInt(value)
                            ) {
                              setClinet(undefined);
                              setAccount(undefined);
                            }
                          } else {
                            setAgency(undefined);
                            setClinet(undefined);
                            setAccount(undefined);
                          }
                        }}
                        data={agencies.map((ag) => ({
                          value: String(ag.agency_id),
                          label: ag.agency_name,
                        }))}
                      />
                    </div>
                    {agency && (
                      <div className="grid grid-cols-3 items-center gap-4">
                        <Label htmlFor="client">Client</Label>
                        <CustomSelect
                          loading={loadingClients}
                          customTrigger={
                            <Button
                              variant="secondary"
                              size="sm"
                              className="col-span-2"
                            >
                              <span className="truncate max-w-[150px]">
                                {client
                                  ? client.customer_name
                                  : "Select Client"}
                              </span>
                              <CaretSortIcon className="size-4 ml-2" />
                            </Button>
                          }
                          value={client ? String(client?.customer_id) : ""}
                          onSelect={(value) => {
                            if (value) {
                              const selectedClient = clients.find(
                                (cl) => cl.customer_id === parseInt(value)
                              );
                              setClinet(selectedClient);
                              if (
                                client &&
                                client.customer_id !== parseInt(value)
                              ) {
                                setAccount(undefined);
                              }
                            } else {
                              setClinet(undefined);
                              setAccount(undefined);
                            }
                          }}
                          data={clients.map((cl) => ({
                            value: String(cl.customer_id),
                            label: cl.customer_name,
                          }))}
                        />
                      </div>
                    )}
                    {client && (
                      <div className="grid grid-cols-3 items-center gap-4">
                        <Label htmlFor="account">Account</Label>
                        <CustomSelect
                          loading={loadingAccounts}
                          customTrigger={
                            <Button
                              variant="secondary"
                              size="sm"
                              className="col-span-2"
                            >
                              <span className="truncate max-w-[150px]">
                                {account
                                  ? account.account_name
                                  : "Select Account"}
                              </span>
                              <CaretSortIcon className="size-4 ml-2" />
                            </Button>
                          }
                          value={account ? String(account?.account_id) : ""}
                          onSelect={(value) => {
                            if (value) {
                              const selectedAccount = accounts.find(
                                (acc) => acc.account_id === parseInt(value)
                              );
                              setAccount(selectedAccount);
                              if (
                                account &&
                                account.account_id !== parseInt(value)
                              ) {
                              }
                            } else {
                              setAccount(undefined);
                            }
                          }}
                          data={accounts.map((acc) => ({
                            value: String(acc.account_id),
                            label: acc.account_name,
                          }))}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>

          <Breadcrumb className="hidden md:block">
            <BreadcrumbList>
              <BreadcrumbItem>
                <CustomSelect
                  loading={loadingAgencies}
                  customTrigger={
                    <Button variant="secondary" size="sm">
                      <span className="truncate max-w-[150px]">
                        {agency ? agency.agency_name : "Select Agency"}
                      </span>
                      <CaretSortIcon className="size-4 ml-2" />
                    </Button>
                  }
                  value={agency ? String(agency?.agency_id) : ""}
                  onSelect={(value) => {
                    if (value) {
                      const selectedAgency = agencies.find(
                        (ag) => ag.agency_id === parseInt(value)
                      );
                      setAgency(selectedAgency);
                      if (agency && agency.agency_id !== parseInt(value)) {
                        setClinet(undefined);
                        setAccount(undefined);
                      }
                    } else {
                      setAgency(undefined);
                      setClinet(undefined);
                      setAccount(undefined);
                    }
                  }}
                  data={agencies.map((ag) => ({
                    value: String(ag.agency_id),
                    label: ag.agency_name,
                  }))}
                />
              </BreadcrumbItem>

              {agency && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <CustomSelect
                      loading={loadingClients}
                      customTrigger={
                        <Button variant="secondary" size="sm">
                          <span className="truncate max-w-[150px]">
                            {client ? client.customer_name : "Select Client"}
                          </span>
                          <CaretSortIcon className="size-4 ml-2" />
                        </Button>
                      }
                      value={client ? String(client?.customer_id) : ""}
                      onSelect={(value) => {
                        if (value) {
                          const selectedClient = clients.find(
                            (cl) => cl.customer_id === parseInt(value)
                          );
                          setClinet(selectedClient);
                          if (
                            client &&
                            client.customer_id !== parseInt(value)
                          ) {
                            setAccount(undefined);
                          }
                        } else {
                          setClinet(undefined);
                          setAccount(undefined);
                        }
                      }}
                      data={clients.map((cl) => ({
                        value: String(cl.customer_id),
                        label: cl.customer_name,
                      }))}
                    />
                  </BreadcrumbItem>
                </>
              )}

              {client && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <CustomSelect
                      loading={loadingAccounts}
                      customTrigger={
                        <Button variant="secondary" size="sm">
                          <span className="truncate max-w-[150px]">
                            {account ? account.account_name : "Select Account"}
                          </span>
                          <CaretSortIcon className="size-4 ml-2" />
                        </Button>
                      }
                      value={account ? String(account?.account_id) : ""}
                      onSelect={(value) => {
                        if (value) {
                          const selectedAccount = accounts.find(
                            (acc) => acc.account_id === parseInt(value)
                          );
                          setAccount(selectedAccount);
                          if (
                            account &&
                            account.account_id !== parseInt(value)
                          ) {
                          }
                        } else {
                          setAccount(undefined);
                        }
                      }}
                      data={accounts.map((acc) => ({
                        value: String(acc.account_id),
                        label: acc.account_name,
                      }))}
                    />
                  </BreadcrumbItem>
                </>
              )}
            </BreadcrumbList>
          </Breadcrumb>

          <div className="flex gap-2">
            {isAdmin && (
              <Link
                to="/admin"
                className={cn(
                  buttonVariants({ variant: "default", size: "sm" })
                )}
              >
                Admin dashboard
              </Link>
            )}

            {isAdmin && account && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div
                    className={cn(
                      buttonVariants({ variant: "default", size: "sm" }),
                      "cursor-pointer"
                    )}
                  >
                    Manage account
                  </div>
                </DropdownMenuTrigger>

                <DropdownMenuContent className="w-36">
                  <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                      <Link to="/" className="flex items-center cursor-default">
                        Overview
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/schedules"
                        className="flex items-center cursor-default"
                      >
                        Schedules
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/connections"
                        className="flex items-center cursor-default"
                      >
                        Social connections
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/websites"
                        className="flex items-center cursor-default"
                      >
                        Websites
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/posts"
                        className="flex items-center cursor-default"
                      >
                        Posts
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/schedules/setup"
                        className="flex items-center cursor-default"
                      >
                        Setup schedule
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setIsOpen(true)}>
                      New connection
                    </DropdownMenuItem>

                    <DropdownMenuItem asChild>
                      <Link
                        to="/posts/setup"
                        className="flex items-center cursor-default"
                      >
                        Make a post
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
