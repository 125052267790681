import { useEffect, useState } from "react";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";

import { DataTableHintSubjects } from "./data";
import AddHintSubjectDialog from "./AddHintSubjectDialog";
import { HintSubject } from "types";
import apiClient from "api";
import HintSchedules from "../HintSchedules";

const HintAndTipsSubjects = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [data, setData] = useState<HintSubject[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<HintSubject | null>(
    null
  );

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchHintTipsSubjects, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchHintTipsSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("admin_creative_get_hint_subjects", {
        headers: {
          request: JSON.stringify({ creative_id: account?.account_id }),
        },
      });
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Hint subjects", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Hint subjects
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add hint subject
          </Button>
        </div>
      </div>

      <DataTableHintSubjects
        data={data}
        loading={loading}
        fetchData={fetchHintTipsSubjects}
        setSelectedSubject={setSelectedSubject}
      />

      <HintSchedules selectedSubject={selectedSubject} />

      <AddHintSubjectDialog
        fetchData={fetchHintTipsSubjects}
        open={openAdd}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default HintAndTipsSubjects;
